import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import './component.scss'

import './images/good.svg'
import './images/check.svg'

import 'Components/nurikae/star_rating/component'

const changeState = () => {
  // セレクタ定義
  const ROOT_TARGET_SELECTOR = '.js-changeState-root-target'
  const HELPFUL_TARGET_SELECTOR = '.js-changeState-helpful-target'

  // data属性の定義
  const REVIEW_ID_DATA_NAME = 'review-id'

  // ローカルストレージに保存するkey名の定義
  const LOCAL_STORAGE_KEY_NAME = 'nurikae_already_helpful_list_of_introduce_review'

  // ローカルストレージから押したボタンのリストを取得
  const alreadyHelpfulList = window?.localStorage?.getItem(LOCAL_STORAGE_KEY_NAME)?.split(',')?.filter((v) => v) || []

  // 完了状態のクラス定義
  const DONE_CLASS_NAME = 'review-card__helpful--done'

  // 処理
  const $rootElement = $(ROOT_TARGET_SELECTOR)

  $rootElement.each((index, root) => {
    const $helpfulTarget = $(root).find(HELPFUL_TARGET_SELECTOR)
    const recordId = $(root).data(REVIEW_ID_DATA_NAME)

    // 既に参考になったボタンを押したユーザーの場合は完了状態に変更する
    if (alreadyHelpfulList.includes(String(recordId))) {
      $helpfulTarget.addClass(DONE_CLASS_NAME)
    }
  })
}

$(() => {
  // 「参考になった」の状態変更処理
  changeState()
})
